import { library } from "@fortawesome/fontawesome-svg-core";

import { faHome as farHome } from "@fortawesome/pro-regular-svg-icons/faHome";
library.add(farHome);

import { faHome } from "@fortawesome/pro-solid-svg-icons/faHome";
library.add(faHome);

import { faGlobe } from "@fortawesome/pro-solid-svg-icons/faGlobe";
library.add(faGlobe);

import { faSpinnerThird as farSpinnerThird } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
library.add(farSpinnerThird);

import { faBriefcase } from "@fortawesome/pro-solid-svg-icons/faBriefcase";
library.add(faBriefcase);

import { faCarWrench } from "@fortawesome/pro-solid-svg-icons/faCarWrench";
library.add(faCarWrench);

import { faHouse } from "@fortawesome/pro-solid-svg-icons/faHouse";
library.add(faHouse);

import { faBan } from "@fortawesome/pro-solid-svg-icons/faBan";
library.add(faBan);

import { faBan as falBan } from "@fortawesome/pro-light-svg-icons/faBan";
library.add(falBan);

import { faLockKeyhole } from "@fortawesome/pro-solid-svg-icons/faLockKeyhole";
library.add(faLockKeyhole);

import { faLockKeyholeOpen } from "@fortawesome/pro-solid-svg-icons/faLockKeyholeOpen";
library.add(faLockKeyholeOpen);

import { faMapLocationDot } from "@fortawesome/pro-solid-svg-icons/faMapLocationDot";
library.add(faMapLocationDot);

import { faLocationPin } from "@fortawesome/pro-solid-svg-icons/faLocationPin";
library.add(faLocationPin);

import { faMap } from "@fortawesome/pro-solid-svg-icons/faMap";
library.add(faMap);

import { faFile } from "@fortawesome/pro-solid-svg-icons/faFile";
library.add(faFile);


import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
library.add(faArrowLeft);

import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
library.add(faArrowRight);

import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar";
library.add(faStar);

import { faStar as farStar } from "@fortawesome/pro-regular-svg-icons/faStar";
library.add(farStar);

import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";
library.add(faCircleExclamation);

import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
library.add(faChevronRight);

import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
library.add(faChevronLeft);

import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
library.add(faChevronDown);

import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
library.add(faChevronUp);

import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons/faCalendarDays";
library.add(faCalendarDays);

import { faCalendarWeek } from "@fortawesome/pro-solid-svg-icons/faCalendarWeek";
library.add(faCalendarWeek);

import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
library.add(faMagnifyingGlass);

import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
library.add(faCircleXmark);

import { faCircleStop } from "@fortawesome/pro-solid-svg-icons/faCircleStop";
library.add(faCircleStop);

import { faFileExport } from "@fortawesome/pro-solid-svg-icons/faFileExport";
library.add(faFileExport);

import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
library.add(faCircleInfo);

import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
library.add(faTriangleExclamation);

import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil";
library.add(faPencil);

import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
library.add(faPhone);

import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
library.add(faEnvelope);

import { faEnvelopeDot } from "@fortawesome/pro-solid-svg-icons/faEnvelopeDot";
library.add(faEnvelopeDot);

import { faLink } from "@fortawesome/pro-solid-svg-icons/faLink";
library.add(faLink);

import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
library.add(faUsers);

import { faUserTie } from "@fortawesome/pro-solid-svg-icons/faUserTie";
library.add(faUserTie);

import { faGasPump } from "@fortawesome/pro-solid-svg-icons/faGasPump";
library.add(faGasPump);

import { faChargingStation } from "@fortawesome/pro-solid-svg-icons/faChargingStation";
library.add(faChargingStation);

import { faGears } from "@fortawesome/pro-solid-svg-icons/faGears";
library.add(faGears);

import { faGear } from "@fortawesome/pro-solid-svg-icons/faGear";
library.add(faGear);

import { faCircleQuestion as farCircleQuestion } from "@fortawesome/pro-regular-svg-icons/faCircleQuestion";
library.add(farCircleQuestion);

import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
library.add(faCircleQuestion);

import { faQuestion } from "@fortawesome/pro-solid-svg-icons/faQuestion";
library.add(faQuestion);

import { faLocationArrow as farLocationArrow } from "@fortawesome/pro-regular-svg-icons/faLocationArrow";
library.add(farLocationArrow);

import { faUser as farUser } from "@fortawesome/pro-regular-svg-icons/faUser";
library.add(farUser);

import { faRectangleList as farRectangleList } from "@fortawesome/pro-regular-svg-icons/faRectangleList";
library.add(farRectangleList);

import { faWifiSlash } from "@fortawesome/pro-solid-svg-icons/faWifiSlash";
library.add(faWifiSlash);

import { faCars as farCars } from "@fortawesome/pro-regular-svg-icons/faCars";
library.add(farCars);

import { faCarMechanic } from "@fortawesome/pro-solid-svg-icons/faCarMechanic";
library.add(faCarMechanic);

import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
library.add(faXmark);

import { faXmark as farXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
library.add(farXmark);

import { faArrowDownToBracket } from "@fortawesome/pro-solid-svg-icons/faArrowDownToBracket";
library.add(faArrowDownToBracket);

import { faLock as farLock } from "@fortawesome/pro-regular-svg-icons/faLock";
library.add(farLock);

import { faUserCircle as farUserCircle } from "@fortawesome/pro-regular-svg-icons/faUserCircle";
library.add(farUserCircle);

import { faIdCard as farIdCard } from "@fortawesome/pro-regular-svg-icons/faIdCard";
library.add(farIdCard);

import { faCreditCard as farCreditCard } from "@fortawesome/pro-regular-svg-icons/faCreditCard";
library.add(farCreditCard);

import { faMoneyCheck } from "@fortawesome/pro-regular-svg-icons/faMoneyCheck";
library.add(faMoneyCheck);

import { faMoneyBills as farMoneyBills } from "@fortawesome/pro-regular-svg-icons/faMoneyBills";
library.add(farMoneyBills);

import { faBookOpen as farBookOpen } from "@fortawesome/pro-regular-svg-icons/faBookOpen";
library.add(farBookOpen);

import { faTags as farTags } from "@fortawesome/pro-regular-svg-icons/faTags";
library.add(farTags);

import { faGlobe as farGlobe } from "@fortawesome/pro-regular-svg-icons/faGlobe";
library.add(farGlobe);

import { faComment as farComment } from "@fortawesome/pro-regular-svg-icons/faComment";
library.add(farComment);

import { faBluetooth } from "@fortawesome/pro-solid-svg-icons/faBluetooth";
library.add(faBluetooth);

import { faBluetooth as farBluetooth } from "@fortawesome/pro-regular-svg-icons/faBluetooth";
library.add(farBluetooth);

import { faBluetooth as falBluetooth } from "@fortawesome/pro-light-svg-icons/faBluetooth";
library.add(falBluetooth);

import { faLocationCircle as farLocationCircle } from "@fortawesome/pro-regular-svg-icons/faLocationCircle";
library.add(farLocationCircle);

import { faLocationDot } from "@fortawesome/pro-solid-svg-icons/faLocationDot";
library.add(faLocationDot);

import { faUserSlash as farUserSlash } from "@fortawesome/pro-regular-svg-icons/faUserSlash";
library.add(farUserSlash);

import { faSignOut } from "@fortawesome/pro-solid-svg-icons/faSignOut";
library.add(faSignOut);

import { faPowerOff } from "@fortawesome/pro-solid-svg-icons/faPowerOff";
library.add(faPowerOff);

import { faFaceSadSweat } from "@fortawesome/pro-solid-svg-icons/faFaceSadSweat";
library.add(faFaceSadSweat);

import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare";
library.add(faArrowUpRightFromSquare);

import { faPartyHorn } from "@fortawesome/pro-solid-svg-icons/faPartyHorn";
library.add(faPartyHorn);

import { faCars } from "@fortawesome/pro-solid-svg-icons/faCars";
library.add(faCars);

import { faCalendarPlus } from "@fortawesome/pro-solid-svg-icons/faCalendarPlus";
library.add(faCalendarPlus);

import { faCalendarXmark } from "@fortawesome/pro-solid-svg-icons/faCalendarXmark";
library.add(faCalendarXmark);

import { faStopwatch } from "@fortawesome/pro-solid-svg-icons/faStopwatch";
library.add(faStopwatch);

import { faMoneyBill } from "@fortawesome/pro-solid-svg-icons/faMoneyBill";
library.add(faMoneyBill);

import { faRoad } from "@fortawesome/pro-solid-svg-icons/faRoad";
library.add(faRoad);

import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
library.add(faCircleCheck);

import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
library.add(faCheck);

import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars";
library.add(faBars);

import { faCar } from "@fortawesome/pro-solid-svg-icons/faCar";
library.add(faCar);

import { faCarSide } from "@fortawesome/pro-solid-svg-icons/faCarSide";
library.add(faCarSide);

import { faCarSideBolt } from "@fortawesome/pro-solid-svg-icons/faCarSideBolt";
library.add(faCarSideBolt);

import { faBicycle } from "@fortawesome/pro-solid-svg-icons/faBicycle";
library.add(faBicycle);

import { faTruck } from "@fortawesome/pro-solid-svg-icons/faTruck";
library.add(faTruck);

import { faTruckBolt } from "@fortawesome/pro-solid-svg-icons/faTruckBolt";
library.add(faTruckBolt);

import { faMotorcycle } from "@fortawesome/pro-solid-svg-icons/faMotorcycle";
library.add(faMotorcycle);

import { faShip } from "@fortawesome/pro-solid-svg-icons/faShip";
library.add(faShip);

import { faPlane } from "@fortawesome/pro-solid-svg-icons/faPlane";
library.add(faPlane);

import { faPersonSnowmobiling } from "@fortawesome/pro-solid-svg-icons/faPersonSnowmobiling";
library.add(faPersonSnowmobiling);

import { faForklift } from "@fortawesome/pro-solid-svg-icons/faForklift";
library.add(faForklift);

import { faFilter } from "@fortawesome/pro-solid-svg-icons/faFilter";
library.add(faFilter);

import { faTableColumns } from "@fortawesome/pro-solid-svg-icons/faTableColumns";
library.add(faTableColumns);

import { faSort } from "@fortawesome/pro-solid-svg-icons/faSort";
library.add(faSort);

import { faCarBus } from "@fortawesome/pro-solid-svg-icons/faCarBus";
library.add(faCarBus);

import { faCarBurst } from "@fortawesome/pro-solid-svg-icons/faCarBurst";
library.add(faCarBurst);

import { faBell } from "@fortawesome/pro-solid-svg-icons/faBell";
library.add(faBell);

import { faCarBuilding } from "@fortawesome/pro-solid-svg-icons/faCarBuilding";
library.add(faCarBuilding);

import { faListTree } from "@fortawesome/pro-solid-svg-icons/faListTree";
library.add(faListTree);

import { faArrowTurnDownRight } from "@fortawesome/pro-solid-svg-icons/faArrowTurnDownRight";
library.add(faArrowTurnDownRight);

import { faArrowRightLong } from "@fortawesome/pro-solid-svg-icons/faArrowRightLong";
library.add(faArrowRightLong);

import { faPlug } from "@fortawesome/pro-solid-svg-icons/faPlug";
library.add(faPlug);

import { faSensor } from "@fortawesome/pro-solid-svg-icons/faSensor";
library.add(faSensor);

import { faSquareInfo } from "@fortawesome/pro-solid-svg-icons/faSquareInfo";
library.add(faSquareInfo);

import { faLockOpen } from "@fortawesome/pro-solid-svg-icons/faLockOpen";
library.add(faLockOpen);

import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
library.add(faLock);

import { faBatteryBolt } from "@fortawesome/pro-solid-svg-icons/faBatteryBolt";
library.add(faBatteryBolt);

import { faTire } from "@fortawesome/pro-solid-svg-icons/faTire";
library.add(faTire);

import { faHeartPulse } from "@fortawesome/pro-solid-svg-icons/faHeartPulse";
library.add(faHeartPulse);

import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
library.add(faPlus);

import { faBellExclamation } from "@fortawesome/pro-solid-svg-icons/faBellExclamation";
library.add(faBellExclamation);

import { faCarBattery } from "@fortawesome/pro-solid-svg-icons/faCarBattery";
library.add(faCarBattery);

import { faKey } from "@fortawesome/pro-solid-svg-icons/faKey";
library.add(faKey);

import { faNfc } from "@fortawesome/pro-solid-svg-icons/faNfc";
library.add(faNfc);

import { faServer } from "@fortawesome/pro-solid-svg-icons/faServer";
library.add(faServer);

import { faCalendarClock } from "@fortawesome/pro-solid-svg-icons/faCalendarClock";
library.add(faCalendarClock);

import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
library.add(faClock);

import { faClockRotateLeft } from "@fortawesome/pro-solid-svg-icons/faClockRotateLeft";
library.add(faClockRotateLeft);

import { faList } from "@fortawesome/pro-solid-svg-icons/faList";
library.add(faList);

import { faGripVertical } from "@fortawesome/pro-solid-svg-icons/faGripVertical";
library.add(faGripVertical);

import { faDoorOpen } from "@fortawesome/pro-solid-svg-icons/faDoorOpen";
library.add(faDoorOpen);

import { faHand } from "@fortawesome/pro-solid-svg-icons/faHand";
library.add(faHand);

import { faGauge } from "@fortawesome/pro-solid-svg-icons/faGauge";
library.add(faGauge);

import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons/faLightbulbOn";
library.add(faLightbulbOn);

import { faFire } from "@fortawesome/pro-solid-svg-icons/faFire";
library.add(faFire);

import { faSatellite } from "@fortawesome/pro-solid-svg-icons/faSatellite";
library.add(faSatellite);

import { faCrosshairs } from "@fortawesome/pro-solid-svg-icons/faCrosshairs";
library.add(faCrosshairs);

import { faSignal } from "@fortawesome/pro-solid-svg-icons/faSignal";
library.add(faSignal);

import { faUserGear } from "@fortawesome/pro-solid-svg-icons/faUserGear";
library.add(faUserGear);

import { faRoute } from "@fortawesome/pro-solid-svg-icons/faRoute";
library.add(faRoute);

import { faCardsBlank } from "@fortawesome/pro-solid-svg-icons/faCardsBlank";
library.add(faCardsBlank);

import { faBuilding } from "@fortawesome/pro-solid-svg-icons/faBuilding";
library.add(faBuilding);

import { faBuildings } from "@fortawesome/pro-solid-svg-icons/faBuildings";
library.add(faBuildings);

import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
library.add(faTrash);

import { faFileImage } from "@fortawesome/pro-solid-svg-icons/faFileImage";
library.add(faFileImage);

import { faFilePdf } from "@fortawesome/pro-solid-svg-icons/faFilePdf";
library.add(faFilePdf);

import { faFileLines } from "@fortawesome/pro-solid-svg-icons/faFileLines";
library.add(faFileLines);

import { faFilePlus } from "@fortawesome/pro-solid-svg-icons/faFilePlus";
library.add(faFilePlus);

import { faPalette } from "@fortawesome/pro-solid-svg-icons/faPalette";
library.add(faPalette);

import { faCloud } from "@fortawesome/pro-solid-svg-icons/faCloud";
library.add(faCloud);

import { faSquarePollHorizontal } from "@fortawesome/pro-solid-svg-icons/faSquarePollHorizontal";
library.add(faSquarePollHorizontal);

import { faFileInvoice } from "@fortawesome/pro-solid-svg-icons/faFileInvoice";
library.add(faFileInvoice);

import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons/faPaperPlane";
library.add(faPaperPlane);

import { faCoins } from "@fortawesome/pro-solid-svg-icons/faCoins";
library.add(faCoins);

import { faTable } from "@fortawesome/pro-solid-svg-icons/faTable";
library.add(faTable);

import { faSteeringWheel } from "@fortawesome/pro-solid-svg-icons/faSteeringWheel";
library.add(faSteeringWheel);

import { faLocationExclamation } from "@fortawesome/pro-solid-svg-icons/faLocationExclamation";
library.add(faLocationExclamation);

import { faCommentsQuestion } from "@fortawesome/pro-solid-svg-icons/faCommentsQuestion";
library.add(faCommentsQuestion);

import { faBook } from "@fortawesome/pro-solid-svg-icons/faBook";
library.add(faBook);

import { faTicket } from "@fortawesome/pro-solid-svg-icons/faTicket";
library.add(faTicket);

import { faCopy } from "@fortawesome/pro-solid-svg-icons/faCopy";
library.add(faCopy);

import { faHandHoldingDollar } from "@fortawesome/pro-solid-svg-icons/faHandHoldingDollar";
library.add(faHandHoldingDollar);

import { faBoxesStacked } from "@fortawesome/pro-solid-svg-icons/faBoxesStacked";
library.add(faBoxesStacked);

import { faDoNotEnter } from "@fortawesome/pro-solid-svg-icons/faDoNotEnter";
library.add(faDoNotEnter);

import { faFiles } from "@fortawesome/pro-solid-svg-icons/faFiles";
library.add(faFiles);

import { faAddressBook } from "@fortawesome/pro-solid-svg-icons/faAddressBook";
library.add(faAddressBook);

import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
library.add(faEye);

import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons/faThumbsUp";
library.add(faThumbsUp);

import { faCreditCard } from "@fortawesome/pro-solid-svg-icons/faCreditCard";
library.add(faCreditCard);

import { faFileArrowUp } from "@fortawesome/pro-solid-svg-icons/faFileArrowUp";
library.add(faFileArrowUp);

import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons/faArrowsRotate";
library.add(faArrowsRotate);

import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons/faEyeSlash";
library.add(faEyeSlash);

import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload";
library.add(faDownload);

import { faBold } from "@fortawesome/pro-solid-svg-icons/faBold";
library.add(faBold);

import { faUnderline } from "@fortawesome/pro-solid-svg-icons/faUnderline";
library.add(faUnderline);

import { faItalic } from "@fortawesome/pro-solid-svg-icons/faItalic";
library.add(faItalic);

import { faBackward } from "@fortawesome/pro-solid-svg-icons/faBackward";
library.add(faBackward);

import { faForward } from "@fortawesome/pro-solid-svg-icons/faForward";
library.add(faForward);

import { faFileCertificate } from "@fortawesome/pro-solid-svg-icons/faFileCertificate";
library.add(faFileCertificate);

import { faHandshake } from "@fortawesome/pro-solid-svg-icons/faHandshake";
library.add(faHandshake);

import { faBox } from "@fortawesome/pro-solid-svg-icons/faBox";
library.add(faBox);

import { faPuzzle } from "@fortawesome/pro-solid-svg-icons/faPuzzle";
library.add(faPuzzle);

import { faPuzzlePiece } from "@fortawesome/pro-solid-svg-icons/faPuzzlePiece";
library.add(faPuzzlePiece);

import { faObjectGroup } from "@fortawesome/pro-solid-svg-icons/faObjectGroup";
library.add(faObjectGroup);